 /* /hoho-web/client/src/pages/RedeemProductPage.module.css */

 @font-face {
     font-family: 'AlibabaPuHuiTi';
     /* 自定义字体名称 */
     src: url("../../public/fonts/AlibabaPuHuiTi-3-95-ExtraBold.subset.woff2") format("woff2");
     /* 字体文件路径 */
     font-weight: 800;
     font-style: normal;
 }

 .extraBoldFont {
     font-family: 'AlibabaPuHuiTi', sans-serif;
     font-weight: 800;
 }

 .header {}

 .large.header {
     position: absolute;
     display: flex;
     align-items: center;
     padding: 24px 48px;
 }

 .medium.header {
     position: absolute;
     display: flex;
     align-items: center;
     padding: 24px 48px;
     margin-top: 24px;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 .small.header {
     position: absolute;
     display: flex;
     align-items: center;
     padding: 24px 48px;
     margin-top: 24px;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 .content {
     display: flex;
     justify-content: center;
     max-width: 1200px;
     margin: 0 80px;
     height: 100%;
     align-items: center;
     gap: 48px;
 }

 .large.content {
     display: flex;
     justify-content: center;
     max-width: 1200px;
     margin: 0 80px;
     height: 100%;
     align-items: center;
     gap: 48px;
 }

 .medium.content {
     display: flex;
     flex-direction: column;
     justify-content: center;
     max-width: 1000px;
     margin: 0 36px;
     top: 50%;
     /* transform: translate(-50%,-50%); */
     height: 100%;
     align-items: center;
     gap: 0px;
 }

 .small.content {
     display: flex;
     flex-direction: column;
     justify-content: center;
     max-width: 600px;
     margin: 0 36px;
     margin-top: 72px;

     height: 100%;
     align-items: center;
     gap: 0px;
     top: 50%;
     /* transform: translate(-50%,-50%); */
 }
 .tutorial-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFCF65;
    border-radius: 100px;
    padding: 20px;
    box-shadow: 0px 0px 20px #F2E3C5;
    height: 64px;
    margin-top: 24px;
    font-size: 20px;
}
 .large.tutorial-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFCF65;
    border-radius: 100px;
    padding: 20px;
    box-shadow: 0px 0px 20px #F2E3C5;
    height: 64px;
    margin-top: 24px;
    font-size: 20px;
}
.medium.tutorial-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFCF65;
    border-radius: 100px;
    padding: 20px;
    box-shadow: 0px 0px 20px #F2E3C5;
    height: 32px;
    margin-top: 16px;
    font-size: 16px;
}
.small.tutorial-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFCF65;
    border-radius: 100px;
    padding: 20px;
    box-shadow: 0px 0px 20px #F2E3C5;
    height: 32px;
    margin-top: 16px;
    font-size: 16px;
}
